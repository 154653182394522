/*
    Retina ready styles
*/
@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
  only screen and (min--moz-device-pixel-ratio: 1.5),
  only screen and (-o-min-device-pixel-ratio: 3/2),
  only screen and (min-device-pixel-ratio: 1.5) {
  .accordion.careers .title::after {
    background-image: url("../img/accordion-closed%402x.png");
    background-size: 15px 10px;
  }

  .accordion.careers .title.active::after {
    background-image: url("../img/accordion-opened%402x.png");
    background-size: 15px 10px;
  }

  .accordion.careers .title:hover::after {
    background-image: url("../img/accordion-opened%402x.png");
    background-size: 15px 10px;
  }

  .widget ul > li {
    background-image: url("../img/arrow%402x.png");
    background-size: 7px 10px;
  }

  .tracking .submit {
    background-image: url("../img/tracking-arrow%402x.png");
    background-size: 7px 10px;
  }

  .events-slider .nivo-caption {
    background-image: url("../img/nivo-events%402x.png");
    background-size: 34px 37px;
  }

  .nivo-nextNav {
    background-image: url("../img/nivo-next-nav%402x.png");
    background-size: 8px 12px;
  }

  .nivo-prevNav {
    background-image: url("../img/nivo-prev-nav%402x.png");
    background-size: 8px 12px;
  }

  .widget_search .search-submit,
  .search-submit {
    background-image: url("../img/search%402x.png");
    background-size: 14px 13px;
  }

  .newsletter .submit {
    background-size: 13px 11px;
  }
  .submit.visible {
    background-image: url("../img/subscribe%402x.png");
  }

  .scroll-up {
    background-image: url("../img/to-top%402x.png");
    background-size: 40px 40px;
  }
}
