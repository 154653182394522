@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min--moz-device-pixel-ratio: 1.5), only screen and (-o-min-device-pixel-ratio: 3 / 2), only screen and (min-device-pixel-ratio: 1.5) {
  .accordion.careers .title:after {
    background-image: url("accordion-closed@2x.683d32ea.png");
    background-size: 15px 10px;
  }

  .accordion.careers .title.active:after, .accordion.careers .title:hover:after {
    background-image: url("accordion-opened@2x.04009394.png");
    background-size: 15px 10px;
  }

  .widget ul > li {
    background-image: url("arrow@2x.242dcf9b.png");
    background-size: 7px 10px;
  }

  .tracking .submit {
    background-image: url("tracking-arrow@2x.4b784014.png");
    background-size: 7px 10px;
  }

  .events-slider .nivo-caption {
    background-image: url("nivo-events@2x.722e6708.png");
    background-size: 34px 37px;
  }

  .nivo-nextNav {
    background-image: url("nivo-next-nav@2x.86fe8b5a.png");
    background-size: 8px 12px;
  }

  .nivo-prevNav {
    background-image: url("nivo-prev-nav@2x.8a147c08.png");
    background-size: 8px 12px;
  }

  .widget_search .search-submit, .search-submit {
    background-image: url("search@2x.5ee472aa.png");
    background-size: 14px 13px;
  }

  .newsletter .submit {
    background-size: 13px 11px;
  }

  .submit.visible {
    background-image: url("subscribe@2x.10a108c0.png");
  }

  .scroll-up {
    background-image: url("to-top@2x.a233c7dd.png");
    background-size: 40px 40px;
  }
}

/*# sourceMappingURL=index.92238019.css.map */
